.person {
  padding: 16px;
}

.person .rowOne {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  height: 100%;
}

.person .rowOne .photo {
  flex-basis: 89px;
}

.person .rowOne .photo img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.person .rowOne .nameEtc {
  padding-left: 28px;
  flex-grow: 1;
}

.person .photo {
  max-width: 90px;
  max-height: 120px;
}

.person .photo:empty {
  content: url("../../../icons/bw-logo.png");
  border-radius: 8px;
}

.person .info {
  margin-top: 4px;
  padding: 12px;
  border: 1px solid #EAEAEA;
}

.person .role, .person .name {
  font-size: 16px;
  font-weight: 600;
}

.person .jobTitle {
  font-size: 12px;
}

.person .rowTwo {
  border-top: 1px solid #EAEAEA;
}

.person .field {
  font-size: 14px;
  padding: 4px 0;
  overflow-wrap: anywhere;
}

.person .field .label {
  font-weight: 700;
}


.person .field a {
  color: #288AE2;
  text-decoration: none;
}
