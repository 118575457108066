.crmStats {
  padding: 8px;
}

.crmStats .unread {
  display: table;
  padding: 0 4px 4px 8px;
}

.crmStats .header {
  font-size: 16px;
  font-weight: 600;
  padding: 16px 8px 8px 8px;
}

.crmStats .subHeader {
  display: table-row;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.crmStats .subHeader div.totalHeader {
  display: table-cell;
}

.crmStats .subHeader div.total {
  padding: 2px 16px;
}

.crmStats .total div {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #FFFFFF;
  background-color: #0F4D77;
  border-radius: 4px;
  padding: 1px 4px 1px 3px;
  text-align: center;
}

.crmStats .stats {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.crmStats .stat:nth-of-type(1) {
  border-right: 1px solid #EAEAEA;
  border-bottom: 1px solid #EAEAEA;
}

.crmStats .stat:nth-of-type(2) {
  border-bottom: 1px solid #EAEAEA;
}

.crmStats .stat:nth-of-type(3) {
  border-right: 1px solid #EAEAEA;
  border-top: 1px solid #EAEAEA;
}

.crmStats .stat:nth-of-type(4) {
  border-top: 1px solid #EAEAEA;
}

.crmStats .stat {
  flex-basis: 33%;
  flex-grow: 1;
  padding: 16px;
  text-align: center;
}

.crmStats .stat .label {
  font-size: 16px;
  font-weight: 600;
}

.crmStats .stat .value {
  font-family: Roboto, sans-serif;
  font-size: 32px;
  font-weight: 700;
  color: #0F4D77;
}
