.card {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 365px;
  border: 1px solid #EAEAEA;
  flex: 0 1 453px;
  margin: 8px;
  background-color: white;
  border-radius: 4px;
  max-width: 453px;
}

.card .title {
  flex-basis: 24px;
  padding: 16px 8px 8px 16px;
  border-bottom: 1px solid #EAEAEA;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0.01em;
  text-align: left;
}

.card .content {
  overflow: auto;
  height: calc(100% - 48px);
  width: 100%;
}

.card .content .loading {
  margin: 25% auto;
  text-align: center;
  content: url("../../../icons/preloader.gif")
}

.card .content .warning {
  width: 40px;
  height: 40px;
  margin: 25% auto;
  text-align: center;
  content: url("../../../icons/warning.svg")
}

@media (max-width: 1440px) {
  .card {
    flex-grow: 1;
    max-width: none;
  }
  .trident-referral-card {
    flex-grow: 0 !important;
    min-width: calc(50% - 16px) !important;
  }
}

@media (max-width: 969px) {
  .trident-referral-card {
    flex-grow: 1 !important;
    max-width: 100% !important;
  }
}
