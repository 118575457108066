html, body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  background: #F3F3F3;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  color: #4F4F4F;
}

#root {
  height: 100%;
  background: linear-gradient(#FBFBFB, #F3F3F3);
  display: flex;
  flex-direction: column;
}
