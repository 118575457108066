.listingStats {
  padding: 8px;
}

.listingStats .header {
  font-size: 16px;
  font-weight: 600;
  padding: 16px 8px 8px 8px;
}

.listingStats .stats {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.listingStats .stat {
  flex-basis: 33%;
  flex-grow: 1;
  margin: 8px;
  padding: 16px;
  background-color: #F7F9FA;
  border-radius: 8px;
}

.listingStats .stat .label {
  font-size: 16px;
  font-weight: 400;
  height: 35px;
}

.listingStats .stat .value {
  font-family: Roboto, sans-serif;
  font-size: 48px;
  font-weight: 500;
  color: #0F4D77;
}

.listingStats .stat .comingSoon {
  font-family: Roboto, sans-serif;
  padding-top: 15px;
  font-size: 22px;
  font-weight: 500;
  color: #0F4D77;
}

.listingStats .stats .stat:last-child {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(50% - 45px);
}