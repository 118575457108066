#seeMyLeads {
  align-content: flex-end;
  font-size: 18px;
  font-weight: bold;
  color: #055E6F;
  background: transparent;
  padding: 7px 28px;
  border: solid 1px #055E6F;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 16px
}

@media (max-width: 600px) {
  #seeMyLeads {
    width: 100%;
    margin: 0 0 15px 0;
  }
}

