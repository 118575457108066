#addListing {
  align-content: flex-end;
  font-size: 18px;
  font-weight: bold;
  color: white;
  background: #055E6F;
  padding: 8px 28px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

#addListing::before {
  content: '+ ';
}

@media (max-width: 600px) {
  #addListing {
    width: 100%;
  }
}
