#footer {
  background-color: #FFFFFF;
  color: #939196;
  text-align: right;
  font-size: 16px;
  padding: 10px;
}

.mobile {
  display: none;
}

@media (max-width: 600px) {
  #footer {
    text-align: center;
  }

  .mobile {
    display: inline;
  }
}

@media (max-width: 380px) {
  #footer {
    font-size: 14px;
  }
}
