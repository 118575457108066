.myGallery {
  padding: 4%;
  font-size: 14px;
  font-style: normal;
  line-height: 22px;
  letter-spacing: 0.01em;
  text-align: left;
  width: 90%;
  height: 165px;
  display: flex;
  flex-direction: row;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.photobox {
  flex-grow: 1;
  text-align: center;
  margin: auto;
  padding-top: 15px;
  flex-basis: 33%;
  max-width: 30%;
}

@media (max-width: 800px) {
  .photobox label{
    max-width:100%;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.boatsdotcomGallery {
  content: url('../../../icons/boatsdotcom.svg');
}

.ywGallery {
  content: url('../../../icons/yw.svg');
}

.btolGallery {
  content: url('../../../icons/btol.svg');
}

.boatshop24Gallery {
  content: url('../../../icons/boatshop24.svg');
}

.boatshop24ukGallery {
  content: url('../../../icons/boatshop24_uk.svg');
}

.adbGallery {
  content: url('../../../icons/adb.svg');
}

.boatsandoutboardsGallery {
  content: url('../../../icons/boatsoutboards.svg');
}

.boot24Gallery {
  content: url('../../../icons/boot24.svg');
}

.botentekoopGallery {
  content: url('../../../icons/botentekoop.svg');
}

.botenbankGallery {
  content: url('../../../icons/botenbank.svg');
}

.cosasdebarcosGallery {
  content: url('../../../icons/cosasdebarcos.svg');
}

.yachtfocusGallery {
  content: url('../../../icons/yachtfocus.svg');
  max-height: 72px;
  max-width: 72px;
}

.topbarcosGallery {
  content: url('../../../icons/topbarcos.svg');
  max-height: 72px;
  max-width: 72px;
}

.youboatGallery {
  content: url('../../../icons/youboat.svg');
  max-height: 72px;
  max-width: 72px;
}
