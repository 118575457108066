.whatsNew {
  padding: 8px 8px 8px 8px;
  font-size: 16px;
  font-style: normal;
  line-height: 22px;
  letter-spacing: 0.01em;
  text-align: left;
  width: 90%;
}

.whatsNew .topTip {
  padding-left: 10px;
  font-weight: 600;
}

.paddingTop{
  padding-top: 10px;
}

ul {
 list-style-image: url('../../../icons/ellipse.png');
}

li {
  padding-bottom: 10px;
}

li span {
  position: relative;
  left: 10px;
}

.whatsNew a {
  color: #288AE2;
}
