.trident-referral-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  padding: 19px 27px 32px;
  box-sizing: border-box;
}

.trident-referral-container .tridentLogo {
  content: url("../../../icons/tridentFundingLogo.png");
  flex: 0 0 auto;
  max-width: 280px;
  width: auto;
  position: relative;
}

.trident-referral-container .title-text {
  color: #2c272d;
  font-family: "Open Sans", Helvetica, serif;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  width: 100%;
}

.trident-referral-container .subtitle {
  color: #2c272d;
  font-family: "Open Sans-Regular", Helvetica, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  width: 100%;
}

.trident-referral-container .cta-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  width: 100%;
}

.trident-referral-container .cta-button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  height: 40px;
  background-color: #ffffff;
  border: 0.5px solid #055e6f;
  border-radius: 4px;
  width: 100%;
}

.trident-referral-container .cta-link {
  color: #696969;
  font-family: "Open Sans", Helvetica, serif;
  font-size: 12px;
  font-weight: 700;
  padding-left: 8px;
  white-space: nowrap;
  max-width: fit-content;
  overflow: hidden;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
}

.trident-referral-container .cta-link::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.trident-referral-container .cta-link::-webkit-scrollbar-thumb {
  display: none;
}

.scrollable-container:hover .scrollable-content {
  transform: translateX(-100%);
}

.trident-referral-container .cta-button {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 40px;
  background-color: #055e6f;
  border-radius: 0 4px 4px 0;
  width: 100px;
  min-width: 100px;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;

}

.trident-referral-container .cta-button-text {
  color: #ffffff;
  font-family: "Open Sans-Bold", Helvetica, serif;
  font-size: 16px;
  font-weight: 700;
}

.trident-referral-container .cta-subject {
  color: #2c272d;
  font-family: "Open Sans-Italic", Helvetica, serif;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  width: 100%;
  text-align: center;
}

.trident-referral-container .call-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  width: 100%;
}

.trident-referral-container .call-text {
  color: #2c272d;
  font-family: "Open Sans-Regular", Helvetica, serif;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.trident-referral-container .call-phone {
  color: #288ae2;
  font-family: "Open Sans", Helvetica, serif;
  font-size: 14px;
  font-weight: 700;
  text-decoration: underline;
}

@media (max-width: 1440px) {
  .trident-referral-container {
    flex-grow: 1;
    max-width: none;
  }
}

@media (max-width: 410px) {
  .trident-referral-container {
    flex: 0 1 342px;
    margin: 8px;
    text-align: center;
    padding: 25px 0 32px;
    width: auto;
  }
}
