.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ad {
  box-sizing: border-box;
  flex: 0 1 453px;
  margin: 8px;
  text-align: center;
  padding-top: 25px;
  max-width: 453px;
}

@media (max-width: 1440px) {
  .ad {
    flex-grow: 1;
    max-width: none;
  }
}
